import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 720.000000 549.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,549.000000) scale(0.100000,-0.100000)">
<path d="M0 2745 l0 -2745 3600 0 3600 0 0 2745 0 2745 -3600 0 -3600 0 0
-2745z m3499 2154 c63 -14 184 -72 272 -129 l75 -50 161 0 c155 0 161 -1 177
-22 16 -23 16 -23 6 -1 -14 30 -13 34 13 64 55 65 143 99 175 67 33 -33 -5
-132 -68 -176 -46 -32 -45 -23 -28 -242 11 -151 12 -156 44 -201 112 -155 164
-370 107 -447 -59 -81 -225 -81 -388 0 -41 21 -186 108 -195 118 -3 3 -24 20
-47 38 -42 32 -59 40 -48 22 3 -5 2 -10 -4 -10 -5 0 -13 5 -16 11 -4 6 -12 8
-19 6 -6 -3 -20 1 -31 9 -10 8 -15 14 -10 14 6 0 -5 8 -22 18 -18 9 -47 31
-67 49 l-34 32 -26 -24 c-14 -13 -26 -27 -26 -30 0 -3 12 -24 27 -47 22 -32
28 -55 31 -112 3 -51 10 -79 25 -101 27 -38 35 -44 57 -38 10 3 21 0 24 -6 8
-13 96 -15 96 -2 0 5 -14 11 -32 15 -17 3 -37 11 -45 17 -15 13 -18 69 -4 69
5 0 7 10 3 22 -5 21 4 20 33 -4 6 -5 46 -35 90 -66 65 -48 92 -61 145 -71 96
-18 147 -46 171 -92 21 -42 17 -59 -18 -91 -42 -38 -226 -81 -288 -67 -27 6
-25 7 15 8 l45 2 -45 15 c-60 19 -171 16 -187 -5 -9 -12 -9 -18 0 -23 35 -22
-182 4 -270 33 -59 19 -114 59 -113 83 2 34 38 83 77 101 57 28 59 31 26 48
-42 22 -68 48 -88 87 -23 44 -24 124 -3 166 24 45 86 84 134 84 31 0 46 6 67
28 15 15 27 35 27 43 0 8 -20 43 -43 79 -91 135 -157 280 -192 420 -22 87 -17
199 10 236 44 58 122 77 224 53z m-289 -1599 c36 -6 107 -32 158 -56 162 -78
278 -222 329 -409 22 -83 22 -298 0 -380 -67 -244 -245 -417 -482 -471 -68
-16 -127 -18 -395 -19 l-315 0 -3 665 c-1 366 0 671 3 679 4 11 62 12 323 8
187 -2 344 -9 382 -17z m1430 -5 c253 -60 425 -244 475 -508 72 -378 -142
-729 -490 -804 -65 -13 -138 -17 -395 -17 l-315 -1 -3 665 c-1 366 0 671 3
679 8 22 624 10 725 -14z m1442 -3 c181 -60 276 -182 286 -366 8 -159 -33
-280 -129 -374 -104 -103 -250 -152 -454 -152 l-115 0 -2 -217 -3 -218 -170 0
-170 0 -3 665 c-1 366 0 671 3 678 4 11 69 13 347 10 321 -4 347 -6 410 -26z
m-3872 -297 c104 -54 62 -215 -56 -215 -39 0 -51 5 -80 34 -26 26 -34 42 -34
69 0 53 16 85 52 107 39 24 78 25 118 5z m-306 -265 c14 -5 35 -24 46 -42 63
-103 -43 -218 -150 -163 -42 21 -60 53 -60 103 0 37 6 50 34 78 36 36 78 44
130 24z m314 -6 c12 -8 29 -28 37 -44 55 -107 -60 -213 -163 -150 -95 58 -51
210 61 210 23 0 53 -7 65 -16z m-8 -269 c104 -54 62 -215 -56 -215 -39 0 -51
5 -80 34 -93 93 18 242 136 181z m-587 -15 c72 -57 50 -171 -39 -200 -76 -25
-144 28 -144 111 0 40 5 52 31 78 26 26 38 31 78 31 33 0 56 -6 74 -20z m295
4 c58 -40 65 -123 16 -175 -71 -74 -194 -26 -194 76 0 43 24 89 54 104 32 15
99 13 124 -5z m-600 -271 c37 -23 56 -79 43 -124 -16 -53 -53 -79 -111 -79
-57 0 -93 25 -110 75 -35 106 81 189 178 128z m302 -3 c31 -22 55 -81 46 -116
-4 -15 -20 -39 -36 -55 -24 -24 -37 -29 -78 -29 -43 0 -55 5 -81 31 -26 26
-31 38 -31 78 0 100 97 150 180 91z m317 -12 c36 -37 41 -94 11 -138 -43 -64
-124 -72 -178 -18 -54 53 -35 154 33 183 39 16 103 3 134 -27z m297 2 c20 -20
30 -40 33 -72 5 -40 2 -49 -27 -81 -29 -34 -37 -37 -84 -37 -39 0 -58 5 -75
21 -51 47 -56 123 -11 168 49 49 114 49 164 1z"/>
<path d="M3905 4681 c-2 -2 19 -23 47 -46 l50 -43 47 26 c25 14 62 34 81 44
19 9 28 18 20 19 -30 4 -242 4 -245 0z"/>
<path d="M4145 4633 c-33 -18 -71 -39 -85 -45 -14 -7 -27 -14 -29 -15 -3 -2
25 -34 61 -71 36 -37 86 -93 111 -125 25 -31 47 -54 49 -50 2 5 -1 77 -7 160
-13 190 -15 194 -100 146z"/>
<path d="M3362 3982 c-31 -5 -72 -62 -72 -101 0 -18 7 -46 16 -62 19 -37 30
-33 13 4 -17 38 5 85 47 103 46 19 49 19 94 -22 22 -20 40 -31 40 -25 0 17
-26 66 -43 80 -20 16 -67 28 -95 23z"/>
<path d="M3350 3890 c-24 -24 -25 -43 -4 -64 22 -22 25 -20 12 9 -14 30 5 57
36 53 31 -4 34 -51 4 -72 -17 -12 -19 -16 -6 -16 18 0 48 36 48 57 0 18 -37
53 -55 53 -8 0 -24 -9 -35 -20z"/>
<path d="M3680 3760 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3780 3711 c0 -10 13 -15 65 -25 27 -6 26 -4 -9 14 -44 22 -56 25
-56 11z"/>
<path d="M2852 2644 l3 -419 122 2 c106 1 129 4 176 26 136 62 207 195 207
387 0 192 -69 320 -208 386 -50 24 -73 28 -180 32 l-122 5 2 -419z"/>
<path d="M4265 3049 c-3 -8 -4 -196 -3 -419 l3 -405 121 2 c103 2 129 5 175
26 135 60 209 195 209 382 0 198 -68 325 -209 392 -48 22 -73 27 -173 31 -94
4 -119 2 -123 -9z"/>
<path d="M5675 3048 c-3 -7 -4 -97 -3 -198 l3 -185 63 -3 c88 -4 179 21 220
61 44 42 53 67 54 149 1 127 -65 179 -234 186 -77 3 -99 1 -103 -10z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
